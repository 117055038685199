import React from "react"
import { AppBar, Container, Toolbar, Typography, styled } from "@mui/material"

const StyledAppBar = styled(AppBar)({
    backgroundColor:'white',
    color: 'primary.main'
})

const Header = () => {

    return (
        <StyledAppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h5"
                        noWrap
                        sx={{
                            mr: 2,
                            display: 'flex',
                            fontWeight:600,
                            color:'primary.main',
                            textDecoration: 'none',
                        }}
                    >
                        CommercialWaste.com
                    </Typography>
                </Toolbar>
            </Container>
        </StyledAppBar>
    )
}

export default Header