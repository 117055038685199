import { createTheme } from "@mui/material";
import "@fontsource/anek-latin/800.css";
import "@fontsource/anek-latin/500.css";
import "@fontsource/anek-latin/300.css";

const defaultTheme = createTheme({
    palette: {
        primary: {
            main: "#00d084"
        }
    },
    typography: {
        h2: {
            fontFamily: 'Anek Latin',
            fontWeight: 800
        },
        h4: {
            fontFamily: 'Anek Latin',
            fontWeight: 500
        },
        h5: {
            fontFamily: 'Anek Latin',
            fontWeight: 500
        },
        h6: {
            fontFamily: 'Anek Latin',
            fontWeight: 300
        },
        body1: {
            fontFamily: 'Anek Latin'
        },
        body2: {
            fontFamily: 'Anek Latin'
        }
    },
    components: {
        MuiCssBaseline: {
          styleOverrides: {
            body: {
            }
          }
        }
    } 
})

export default defaultTheme