import React from "react"

import ThemeProviderWrapper from "../theme"
import Header from "../components/header"
import Footer from "../components/footer"

const Layout = ({children}) => {

    return (
        <ThemeProviderWrapper>
            <main>
                <Header />
                {children}
                <Footer />
            </main>
        </ThemeProviderWrapper>
    )
}

export default Layout