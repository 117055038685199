import React from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";

const Footer = () => {

    const theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Box 
            sx={{
                width:'100%', 
                textAlign: 'center', 
                py: isLargeScreen ? 10 : 2 }}>
            {
                isLargeScreen &&
                <Typography variant="h6">Copyright © 2022 CommercialWaste.com. Powered by Waste Expert Ltd</Typography>
            }
            {
                !isLargeScreen &&
                <Typography variant="body2">Copyright © 2022 CommercialWaste.com. Powered by Waste Expert Ltd</Typography>
            }
        </Box>
    )
}

export default Footer