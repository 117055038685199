import React from "react"
import { CssBaseline, ThemeProvider } from "@mui/material"

import defaultTheme from "./defaultTheme"

const ThemeProviderWrapper = ({children}) => {

    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline ></CssBaseline>
            {children}
        </ThemeProvider>
    )
}

export default ThemeProviderWrapper